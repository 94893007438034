import React from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";
import { IndustryPageData, ZigZagSectionDataIndustryProductive } from "../../data/industry";
import { ZigZagSection } from "../../components/rebrand/sections/ZigZag/ZigZag";
const { initialNavigationTheme, industryProductivePageTitleData } = IndustryPageData;

interface IndustryProductivePageProps extends PageProps {
  data: Queries.IndustryPageQuery;
}

export const pageQuery = graphql`
  query IndustryPage {
    site {
      siteMetadata {
        pages {
          industry {
            industry {
              title
              description
              keywords
            }
          }
        }
      }
    }
  }
`;

export default function IndustryProductivePage(props: IndustryProductivePageProps) {

  const {
    location: { pathname },
    data:  { site }
  } = props;

  const { title, description, keywords } = site?.siteMetadata?.pages?.industry?.industry as Queries.IndustryProductivePage;

  return (
    <Layout initialNavigationTheme={initialNavigationTheme}>
      <SEO title={title} description={description} keywords={[...keywords]} pathname={pathname} />
      <PageHeaderSection theme={industryProductivePageTitleData.theme} title={industryProductivePageTitleData.title} />
      <ZigZagSection {...ZigZagSectionDataIndustryProductive} />
    </Layout>
  );

}
